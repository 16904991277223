<template>
    <teleport to=".sc-modals-anchor">
    <div ref="scModal" v-vb-is:modal.show @vb-hide-bs-modal="canModalHide"
         @vb-hidden-bs-modal="modalWasHidden"
         class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content shadow-lg">
                <div class="modal-header">
                    <button class="btn-close" data-bs-dismiss="modal"></button>
                    <h4 class="modal-title w-100 text-center">Delete</h4>
                </div>
                <div class="modal-body">
                    <p>Do you really want to delete {{ deleteList }}?</p>
                    <p v-if="!isFolder && selectedItems.length > 0">
                        <span v-for="item in selectedItems" class="m-0 d-block" :key="item.id">
                            &ndash; {{ item.name }}
                        </span>
                    </p>
                    <p v-else class="m-0">&ndash; {{ itemName }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary-orange fw-bold"
                            :disabled="deleteLoading" @click.prevent="deleteSelectedItems">
                        <ScIcon v-if="deleteLoading" name="spinnerFW" class="mx-3" />
                        <span v-else>Delete</span></button>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import MainAppMixin from "../../MainAppMixin";
    import ScIcon from '../../../shared/common/ScIcon.vue';

    export default {
        name: 'LibraryDeleteModal',
        emits: ['delete-complete', 'modal-was-hidden'],
        mixins: [MainAppMixin],
        components: {ScIcon},
        props: {
            libStorePrefix: {type: String, default: 'libPage'},
            activeItem: {type: Object, default: null},
            selectedItems: {type: Array, default: null}
        },
        data () {
            return {
                deleteLoading: false
            }
        },
        methods: {
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            canModalHide(e) {
                if (this.deleteLoading) e.preventDefault();
            },
            modalWasHidden() {
                this.$emit('modal-was-hidden');
            },
            deleteSelectedItems() {
                this.deleteLoading = true;
                if (this.isFolder) {
                    this.$store.dispatch(this.libStorePrefix + '/libDeleteFolder', {
                        workspaceId: this.mainMxCurrentWorkshopId, folderId: this.activeItem.id
                    }).then(() => {
                        this.deleteLoading = false;
                        this.$emit('delete-complete');
                        this.hideModal();
                    }, () => {
                        this.deleteLoading = false;
                    });

                } else {
                    let deleteDocWithPromise = (doc) => {
                        return new Promise((resolve) => {
                            if (this.isFile(doc)) {
                                this.$store.dispatch(this.libStorePrefix + '/libDeleteFile', {
                                    workspaceId: this.mainMxCurrentWorkshopId, fileId: doc.id, name: doc.simplename
                                }).then(resolve, resolve);
                            } else {
                                resolve();
                            }
                        })
                    };
                    let itemsToDelete = [...this.selectedItems]
                    let delNextFile = () => {
                        let doc = itemsToDelete.shift();
                        if (doc) {
                            deleteDocWithPromise(doc).then(delNextFile);
                        }   else {
                            this.deleteLoading = false;
                            this.$emit('delete-complete');
                            this.hideModal();
                        }
                    }
                    delNextFile();
                }
            },
            isFile (doc) {
                return doc && doc.content_type !== 'folder';
            },
        },
        computed: {
            deleteList () {
                return this.isFolder ? 'this folder' : (this.selectedItems.length === 1  ? 'this file' : 'these ' + this.selectedItems.length + ' files');
            },
            itemName () {
                return (this.activeItem) ? this.activeItem.name : '';
            },
            isFolder () {
                //console.log('isFolder', this.activeItem);
                return this.activeItem && this.activeItem.content_type === 'folder';
            }
        },
    }

</script>