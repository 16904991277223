<template>
    <teleport to=".sc-modals-anchor">
    <div ref="scModal" v-vb-is:modal.show @vb-hide-bs-modal="canModalHide"
         @vb-hidden-bs-modal="modalWasHidden"
         class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content shadow-lg">
                <div class="modal-header">
                    <button class="btn-close" data-bs-dismiss="modal"></button>
                    <h4 class="modal-title w-100 text-center">Move to folder</h4>
                </div>
                <div class="modal-body">
                    <div v-if="showSpinner" class="text-center px-3">
                        <ScIcon name="spinnerFW" class="text-muted" />
                    </div>
                    <ul v-else class=" list-unstyled list-group folder-select border">
                        <li v-for="(folder,idx) in folderList" :key="folder.id"
                            :value="folder.id" :disabled="folder.id === storeLibState.selectedFolderId"
                            @click="selectFolder(folder.id)"
                            class="list-group-item border-bottom-0 py-2"
                            :class="newFolderId === folder.id ? 'primary-highlighted text-white' : ''">
                            <span v-for="i in folder.depth" :key="'spacer-'+folder.id+'-'+i">
                                <span v-if="i > -1" class="ms-3">&nbsp;</span><!-- not needed, compiler reports unused "i" without -->
                            </span>
                            <span v-if="idx > 0" class="ms-3">&nbsp;</span><!-- indentation from root folder -->
                            <ScIcon name="levelUpFWR" class="me-2"/>{{ folder.name }}
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary-orange fw-bold" @click.prevent="moveSelectedItems"
                            :disabled="newFolderId === 0 || newFolderId === storeLibState.selectedFolderId || moveLoading">
                        <ScIcon v-if="moveLoading" name="spinnerFW" class="mx-3" />
                        <span v-else>Move</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import $ from "jquery";
    import _ from "underscore"; // where

    import ScNotification from "../../../shared/common/ScNotification.vue";
    import ScIcon from "../../../shared/common/ScIcon.vue";
    import MainAppMixin from "../../MainAppMixin";

    let _templateFile = {
        id: null,
        name: null,
        parent_id: null
    };

    export default {
        name: 'LibraryMoveModal',
        emits: ['move-complete', 'modal-was-hidden'],
        components: {ScIcon},
        mixins: [MainAppMixin],
        props: {
            libStorePrefix: {type: String, default: 'libPage'},
            selectedItems: {type: Array, default: null}
        },
        data () {
            return {
                newFolderId: 0,
                showSpinner: false,
                loadedFolder: [],
                moveLoading: false
            };
        },
        mounted () {
            this.loadedFolders()
        },
        methods: {
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            canModalHide(e) {
                if (this.moveLoading) e.preventDefault();
            },
            modalWasHidden() {
                this.$emit('modal-was-hidden');
            },
            loadedFolders () {
                this.showSpinner = true;
                this.loadedFolder.splice(0);
                $.ajax({
                    type: 'GET', url: '/main/library/ajax_folders_list',
                    data: {workspace_id: this.mainMxCurrentWorkshopId}
                }).done((data) => {
                    //console.log('fetchAllFolders', data);
                    if (!data.folders) {
                        ScNotification.growlErrMsg('Error fetching folders');
                    }   else {
                        data.folders.forEach((f) => {
                            let folder = Object.assign({}, _templateFile, f);
                            folder.content_type = 'folder';
                            this.loadedFolder.push(folder);
                        });
                    }
                }).always(() => {
                    this.showSpinner = false;
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'fetching folders');
                });
            },
            findChildFolder (i,list, d) {
                d++;
                let item = this.hasChild(i.id);
                let l = item.length;
                while (l > 0) {
                    list.push({name: item[l-1].name, id: item[l-1].id, depth: d});

                    if (this.hasChild(item[l-1].id))
                        this.findChildFolder(item[l-1], list, d);

                    l--;
                }
                return list;
            },
            hasChild (id) {
                return _.where(this.loadedFolder, {parent_id: id});
            },
            selectFolder (id) {
                if (this.moveLoading) return;
                if (id === this.storeLibState.selectedFolderId) {
                    ScNotification.growlErrMsg('You can not move to own folder.');
                } else {
                    this.newFolderId = id;
                }
            },
            moveSelectedItems () {
                this.moveLoading = true;
                let moveItems = [];
                for (let item of this.selectedItems) {
                    moveItems.push(item.id);
                }
                this.$store.dispatch(this.libStorePrefix + '/libMoveFile',
                    {workspaceId: this.mainMxCurrentWorkshopId, moveItems: moveItems, moveToFolder: this.newFolderId}
                ).then(() => {
                    this.newFolderId = 0;
                    this.$emit('move-complete');
                    this.moveLoading = false;
                    this.hideModal();
                }, () => {});
            },
        },
        computed: {
            storeLibState() {
                return this.$store.state[this.libStorePrefix];
            },
            folderList () {
                let d = 0;
                let list = [{name:'File Library', id: this.storeLibState.rootFolderId, depth: d}];

                for (let i of this.hasChild(this.storeLibState.rootFolderId)) {
                    list.push({name: i.name, id: i.id, depth: d});
                    list = this.findChildFolder(i, list, d);
                }
                return list;
            }
        },
    }

</script>
<style scoped>

    ul.folder-select {
        min-height: 40vh;
        max-height: 45vh;
        overflow: auto;
    }
    ul.folder-select .list-group-item {
        cursor: pointer !important;
    }

</style>
