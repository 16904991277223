<template>
    <div v-if="storeLibState && storeLibState.rootFolderId" class="pb-2 px-3 mt-2">
        <div class="btn-toolbar lib-btn-toolbar mx-2" role="toolbar">

            <button :class="['btn btn-primary-orange fw-bold px-3', searchedVal ? 'disabled' : '']"
                    @click.prevent="openUpload">
                <span class="h5 mb-0 text-white" v-sc-tooltip="searchedVal ? 'To upload files, clear any active search results first' : 'Upload files from your computer'">Upload</span>
            </button>
            <button :class="['btn px-3', searchedVal ? 'disabled' : '']" @click.prevent="openCloudUpload"
                    v-sc-tooltip="searchedVal ? 'To upload files, clear any active search results first' : 'Upload files from a cloud service'">
                <ScIcon name="cloudUploadFW" class="h5 mb-0"/>
            </button>
            <button :class="['btn px-3', searchedVal ? 'disabled' : '']"
                    @click.prevent="createNewFolder" >
                <span class="h5 mb-0" v-sc-tooltip="searchedVal ? 'To create a new folder, clear any active search results first' : 'New folder'">
                    <ScIcon name="folderPlusFW"/></span>
            </button>

            <button class="btn btn-outline-secondary btn-sc-toolbar dropdown-toggle text-center active px-3 mx-2"
                    data-bs-toggle="dropdown" role="button" aria-expanded="false"
                    style="/*width: 65px;*/" v-sc-tooltip="'Filter for file type'">
                <ScIcon v-if="storeLibState.filterType === 'all'" name="asteriskFW" class="h5 mb-0"/>
                <ScIcon v-if="storeLibState.filterType === 'image'" name="imageFW" class="h5 mb-0"/>
                <ScIcon v-if="storeLibState.filterType === 'movie'" name="filmFW" class="h5 mb-0"/>
                <ScIcon v-if="storeLibState.filterType === 'cinemagraph'" name="fileVideoFW" class="h5 mb-0"/>
                <ScIcon v-if="storeLibState.filterType === 'image:gif'" name="fileVideoFW" class="h5 mb-0"/>
                <ScIcon v-if="storeLibState.filterType === 'document'" name="fileFW" class="h5 mb-0"/>
            </button>
            <div class="dropdown-menu dropdown-menu-right">

                    <button type="button" @click="changeFilterType('all')"
                       :class="['dropdown-item text-start', storeLibState.filterType === 'all' ? 'active' : '']">
                        <ScIcon name="asteriskFW" class="me-2"/> Show all files
                    </button>
                    <button type="button" @click="changeFilterType('image')"
                       :class="['dropdown-item text-start', storeLibState.filterType === 'image' ? 'active' : '']">
                        <ScIcon name="imageFW" class="me-2"/> Show only images
                    </button>
                    <button type="button" @click="changeFilterType('movie')"
                       :class="['dropdown-item text-start', storeLibState.filterType === 'movie' ? 'active' : '']">
                        <ScIcon name="filmFW" class="me-2"/> Show only videos
                    </button>
                    <button type="button" @click="changeFilterType('cinemagraph')"
                       :class="['dropdown-item text-start', storeLibState.filterType === 'cinemagraph' ? 'active' : '']">
                        <ScIcon name="fileVideoFW" class="me-2"/> Show only cinemagraphs
                    </button>
                    <button type="button" v-if="storeLibState.requestedFilterType === 'image:gif'" href="#" @click="changeFilterType('image:gif')"
                       :class="['dropdown-item text-start', storeLibState.filterType === 'image:gif' ? 'active' : '']">
                        <ScIcon name="fileVideoFW" class="me-2"/> Show only gifs
                    </button>
                    <button type="button" @click="changeFilterType('document')"
                       :class="['dropdown-item text-start', storeLibState.filterType === 'document' ? 'active' : '']">
                        <ScIcon name="fileFW" class="me-2"/> Show only documents
                    </button>

            </div>

            <div v-if="!!searchedVal" class="btn-group">
                <button :disabled="true"  class="btn text-center px-3">
                    <ScIcon name="sortAmountDownFW" class="h5 mb-0" v-sc-tooltip="'Sorted by relevance'"/>
                </button>
            </div>
            <div v-if="!searchedVal" class="btn-group dropdown">
                 <button class="btn dropdown-toggle btn-sc-toolbar text-center active px-3"
                         data-bs-toggle="dropdown" role="button" aria-expanded="false"
                         style="width: 65px;" v-sc-tooltip="'Sort'">
                     <ScIcon v-if="userPrefs.library_order_by === 'id_desc'" name="clockFW" class="h5 mb-0" :v-sc-tooltip="'Sort order'"/>
                     <ScIcon v-if="userPrefs.library_order_by === 'name_asc'" name="sortAlphaDownFW" class="h5 mb-0" :v-sc-tooltip="'Sort order'"/>
                     <ScIcon v-if="userPrefs.library_order_by === 'file_id_desc'" name="clockFW" class="h5 mb-0" :v-sc-tooltip="'Sort order'"/>
                     <ScIcon v-if="userPrefs.library_order_by === 'file_name_asc'" name="sortAlphaDownFW" class="h5 mb-0" :v-sc-tooltip="'Sort order'"/>
                 </button>
                <div class="dropdown-menu">

                        <button type="button" @click="changeSortOrder('name_asc')"
                           :class="['dropdown-item text-start', userPrefs.library_order_by === 'name_asc' ? 'active' : '']">
                            <ScIcon name="sortAlphaDownFW" class="me-2"/>
                            <span class="me-3">A-Z</span>
                        </button>

                        <button type="button" @click="changeSortOrder('id_desc')"
                           :class="['dropdown-item text-start', userPrefs.library_order_by === 'id_desc' ? 'active' : '']">
                            <ScIcon name="clockFW" class="me-2"/>
                            <span class="me-3">Most recent</span>
                        </button>

                        <button type="button" @click="changeSortOrder('file_name_asc')"
                           :class="['dropdown-item text-start', userPrefs.library_order_by === 'file_name_asc' ? 'active' : '']">
                            <ScIcon name="sortAlphaDownFW" class="me-2"/>
                            <span class="me-3">Files first, A-Z</span>
                        </button>

                        <button type="button" @click="changeSortOrder('file_id_desc')"
                           :class="['dropdown-item text-start', userPrefs.library_order_by === 'file_id_desc' ? 'active' : '']">
                            <ScIcon name="clockFW" class="me-2"/>
                            <span class="me-3">Files first, most recent</span>
                        </button>

                </div>
            </div>


            <button v-if="userPrefs.library_view_style === 'grid'" @click.prevent="changeViewStyle('list')"
                    type="button" class="btn px-3" v-sc-tooltip="'View as list'">
                <ScIcon name="listFW" class="h5 mb-0"/>
            </button>
            <button v-if="userPrefs.library_view_style === 'list'" @click.prevent="changeViewStyle('grid')"
                    type="button" class="btn px-3" v-sc-tooltip="'View as grid'">
                <ScIcon name="thLargeFW" class="h5 mb-0"/>
            </button>


            <button v-if="itemSelect === 'none'" @click.prevent="$emit('item-select', 'all')"
                    type="button" class="btn px-3" v-sc-tooltip="'Select all'">
                <ScIcon name="checkSquareFW" class="h5 mb-0"/>
            </button>
            <button v-if="itemSelect === 'all'" @click.prevent="$emit('item-select', 'none')"
                    type="button" class="btn px-3" v-sc-tooltip="'Select none'">
                <ScIcon name="checkSquareFW" class="h5 mb-0"/>
            </button>


            <template v-if="selectedItems.length > 0" >
                <div class="btn-group ms-auto" role="group">
                    <template v-if="!searchedVal && allSelectedFilesAreReady">
                        <button @click.prevent="$emit('open-modal', {modalType: 'moveModal'})"
                                type="button" class="btn px-3" v-sc-trackevent="'files:resource_move'"
                                v-sc-tooltip="'Move'">
                            <ScIcon name="arrowFromLeftFW" class="h5 mb-0"/>
                        </button>
                    </template>

                    <button v-if="allSelectedFilesAreReady"
                            @click.prevent="$emit('open-modal', {activeItem: activeItem, modalType: 'deleteFileModal'})"
                            type="button" class="btn px-3" v-sc-trackevent="'files:resource_delete'"
                            v-sc-tooltip="'Delete'">
                        <ScIcon name="trashAltFW" class="h5 mb-0"/>
                    </button>

                    <template v-if="selectedItems.length === 1 && allSelectedFilesAreReady">

                        <button @click.prevent="$emit('open-modal', {activeItem: activeItem, modalType: 'renameModal'})"
                                type="button" class="btn px-3" v-sc-trackevent="'files:resource_rename'"
                                v-sc-tooltip="'Rename'">
                            <ScIcon name="pencilFW" class="h5 mb-0"/>
                        </button>

                        <a :href="downloadLink" class="btn my-auto px-3" v-sc-trackevent="'files:resource_download'"
                            style="color:#000" v-sc-tooltip="'Download'">
                            <ScIcon name="downloadFW" class="h5 mb-0"/>
                        </a>

                        <button @click.prevent="$emit('open-modal', {activeItem: activeItem, modalType: 'usagesModal'})"
                                type="button" class="btn px-3" v-sc-trackevent="'files:resource_usages'"
                                :disabled="storeLibState.findUsagesActive" v-sc-tooltip="'Usages'">
                            <ScIcon name="mapMarkerQuestionFW" class="h5 mb-0"/>
                        </button>

                        <button @click.prevent="$emit('open-modal', {activeItem: activeItem, modalType: 'resourceInfoModal'})"
                                type="button" class="btn px-3" v-sc-trackevent="'files:resource_info_popover'"
                                v-sc-tooltip="'File info'">
                            <ScIcon name="infoCircleFW"/></button>

                    </template>

                    <template v-if="selectedItems.length === 1">
                        <div class="text-muted my-auto align-self-center">
                            <p class="btn m-0 p-0 text-muted">{{ getByteSize }}</p>
                        </div>
                    </template>
                </div>
            </template>

            <div class="input-group ms-auto py-1" style="width: 187px">
                <input type="search" placeholder="Search" class="form-control"
                       v-model="searchValue" @keyup.enter="returnSearchValue" @search="clearSearch" />
                <button @click="returnSearchValue" class="btn btn-outline-secondary input-group-text px-3"><ScIcon name="searchFW"/></button>
            </div>

        </div>

    </div>
</template>


<script>

    import ScCommonUtil from "../../shared/common/ScCommonUtil";
    import MainAppMixin from "../MainAppMixin";
    import constants from "../../constants";
    import ScIcon from "../../shared/common/ScIcon.vue";

    export default {
        name: 'LibraryButtonBar',
        emits: ['filter-type-changed', 'open-modal', 'user-search', 'local-upload', 'cloud-upload', 'item-select'],
        mixins: [MainAppMixin],
        components: {ScIcon},
        data () {
            return {
                searchValue: null
            }
        },
        props: {
            itemSelect: {type: String, default: null},
            selectedItems: {type: Array, default: null},
            searchedVal: {type: String, default: null},
            libStorePrefix: {type: String, default: 'libPage'}
        },
        mounted () {
            this.searchValue = this.searchedVal;
        },
        methods: {
            changeViewStyle (style) {
                this.$store.dispatch('userSaveLibPrefs', {
                    viewStyle: style,
                    sortOrder: this.userPrefs.library_order_by
                });
            },
            changeSortOrder (order) {
                this.$store.dispatch('userSaveLibPrefs', {
                    viewStyle: this.userPrefs.library_view_style,
                    sortOrder: order
                });
            },
            changeFilterType (val) {
                this.$store.commit(this.libStorePrefix + '/libSetFilterType', {filterType: val})
                this.$emit('filter-type-changed', val);
            },
            clearSearch () {
                if (!this.searchValue)
                    this.$emit('user-search', null)
            },
            returnSearchValue () {
                this.$emit('user-search', this.searchValue);
            },
            openUpload () {
                if (!this.searchedVal)
                    this.$emit('local-upload');
            },
            openCloudUpload () {
                if (!this.searchedVal)
                    this.$emit('cloud-upload');
            },
            createNewFolder (newFolderName) {
                if (!this.searchedVal && newFolderName)
                    this.$emit('open-modal', {modalType: 'createFolderModal', newFolderName: newFolderName});
            }
        },
        computed: {
            userPrefs() {
                return this.$store.state.user;
            },
            storeLibState() {
                return this.$store.state[this.libStorePrefix];
            },
            activeItem () {
                return this.selectedItems[0];
            },
            getByteSize () {
                return ScCommonUtil.filesizeWithBytes(this.selectedItems[0]['bytesize']);
            },
            downloadLink () {
                if(this.selectedItems.length === 1)
                    return '/main/library/download/' + this.selectedItems[0].id +
                        '?workspace_id=' + this.mainMxCurrentWorkshopId;
                return '#';
            },
            allSelectedFilesAreReady() {
                let numReady = 0;
                this.selectedItems.forEach((f) => {
                    if (f && f.state_const === constants.RESOURCESTATE_READY) numReady++;
                });
                return numReady === this.selectedItems.length;
            }
        },
        watch: {
            searchedVal (nv) {
                this.searchValue = nv;
            }

        }

    }

</script>
