<template>
  <teleport to=".sc-modals-anchor">
    <div ref="scModal" v-vb-is:modal.show @vb-hide-bs-modal="canModalHide"
         @vb-hidden-bs-modal="modalWasHidden"
         class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content shadow-lg">
          <div class="modal-header">
            <button class="btn-close" data-bs-dismiss="modal"></button>
            <h4 class="modal-title w-100 text-center">
                File Usages</h4>
          </div>
          <div class="modal-body clearfix">
            <div v-if="usagesLoading" class="modal-loading text-center">
              <ScIcon name="spinnerFW" class="text-muted"/>
            </div>

            <div v-else>

              File Usages for: <strong class="text-break">{{ itemName}}</strong>

              <template v-for="(usage, idx) in sortedUsagesByPres" :key="'u'+idx">
                <hr class="my-3">
                <div class="row g-0">
                  <div class="col-auto my-auto" style="width: 64px;">
                    <img :src="getThumbImg(usage,idx)" alt="Thumbnail"
                         class="d-block mx-auto" style="max-width: 50px;max-height: 50px;"/>
                  </div>
                  <div class="col my-auto">{{ usage.presentation.title }}</div>
                  <div class="col-5 my-auto">
                    <template v-for="item in usage.usages" :key="'ivu'+item.id">
                      <span v-if="item.type === 'intro_video'" class="d-block">
                        <router-link :to="{ name: 'editor',
                            params: { workshopId: mainMxCurrentWorkshopId, showcaseId: usage.presId }}">Intro video</router-link>
                      </span>
                    </template>

                    <template v-for="item in usage.usages" :key="'svu'+item.id">
                      <span v-if="item.type === 'screensaver_video'" class="d-block">
                        <router-link :to="{ name: 'editor',
                            params: { workshopId: mainMxCurrentWorkshopId, showcaseId: usage.presId }}">Screensaver video</router-link>
                      </span>
                    </template>

                    <span v-if="usage.shareableAllSlides || usage.pageShareableUsages.length > 0" class="d-block">
                      Shareable on:
                      <template v-if="usage.shareableAllSlides">
                        &nbsp; <router-link :to="{ name: 'editor',
                            params: { workshopId: mainMxCurrentWorkshopId, showcaseId: usage.presId}}">All slides</router-link>
                      </template>
                      <template v-for="page in usage.pageShareableUsages" :key="'ssu'+page.id">
                        &nbsp; <router-link :to="{ name: 'editor',
                             params: { workshopId: mainMxCurrentWorkshopId, showcaseId: usage.presId},
                             query: {pid: page.id}}">{{ page.alpha_num_name }}</router-link>
                      </template>
                    </span>

                    <span v-if="usage.pageBgUsages.length > 0" class="d-block">
                      Slide background on:
                      <template v-for="page in usage.pageBgUsages" :key="'sbu'+page.id">
                        &nbsp; <router-link :to="{ name: 'editor',
                             params: { workshopId: mainMxCurrentWorkshopId, showcaseId: usage.presId},
                             query: {pid: page.id}}">{{ page.alpha_num_name }}</router-link>
                      </template>
                    </span>

                    <span v-if="usage.hsTargetUsages.length > 0" class="d-block">
                      Hotspot {{formatCountWording(usage.hsTargetUsages.length, 'target', 's')}} on:
                      <template v-for="page in usage.hsTargetUsages" :key="'htu'+page.id">
                        &nbsp; <router-link :to="{ name: 'editor',
                             params: { workshopId: mainMxCurrentWorkshopId, showcaseId: usage.presId},
                             query: {pid: page.id}}">{{ page.alpha_num_name }}</router-link>
                      </template>
                    </span>

                    <span v-if="usage.hsImgUsages.length > 0" class="d-block">
                      Hotspot {{formatCountWording(usage.hsImgUsages.length, 'image', 's')}} on:
                      <template v-for="(page,idx) in usage.hsImgUsages" :key="'hiu'+idx">
                        &nbsp; <router-link :to="{ name: 'editor',
                             params: { workshopId: mainMxCurrentWorkshopId, showcaseId: usage.presId},
                             query: {pid: page.id}}">{{ page.alpha_num_name }}</router-link>
                      </template>
                      <br/>
                      <span class="text-muted">
                        <ScIcon name="questionCircle"/> Hotspot images cannot be replaced by "Replace All Usages".</span>
                    </span>

                    <div v-if="!usage.canEditPresUsage">
                      <small class="text-muted">
                        <ScIcon name="infoCircle" class="me-1"/> You can't currently edit this presentation. Replacements will not be applied.
                      </small>
                    </div>

                  </div>
                </div>

              </template>

              <div class="row g-0 " v-if="sortedUsagesByPres.length > 0">
                <hr class="mt-3 mb-4">
                <button :disabled="totalReplaceableUsages === 0" @click.stop.prevent="replaceButtonClicked"
                        class="btn btn-outline-secondary col-auto">Replace All Usages</button>
                <small class="col ms-3 my-auto"><ScIcon name="questionCircle"/>
                  You will need to <strong>publish each presentation</strong> individually after replacing files.</small>
              </div>
              <div v-else>
                <hr class="my-4">
                <p class="d-flex justify-content-center"><em class="text-muted">File is unused</em></p>
              </div>

            </div>
          </div>
        </div>
        <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>
      </div>
    </div>
  </teleport>

</template>


<script>

    import $ from "jquery";
    import _ from "underscore"; // findWhere, contains, sortBy
    import ScNotification from "../../../shared/common/ScNotification.vue";
    import ScIcon from "../../../shared/common/ScIcon.vue";
    import ScConfirmModal2 from "../../../shared/common/ScConfirmModal2.vue";

    import MainAppMixin from "../../MainAppMixin";
    import { defineAsyncComponent } from 'vue';

    export default {
      name: 'LibraryUsageModal',
      emits: ['modal-was-hidden'],
      mixins: [MainAppMixin],
      components: { ScIcon,
        LibraryMainModal: defineAsyncComponent(
            () => import('../LibraryMainModal.vue')  // defined as async to avoid circular dependency
        )},
      props: {
        libStorePrefix: {type: String, default: 'libPage'},
        activeItem: {type: Object, default: null}
      },
      data () {
        return {
          usagesLoading: false,
          replaceLoading: false,
          loadedUsages: [],
          usageByPresentation: [],
          totalReplaceableUsages: 0,
          reloadEditorOnHidden: false,
        };
      },
      mounted () {
        this.loadData();
      },
      methods: {
        canModalHide (e) {
          if (this.replaceLoading) e.preventDefault();
        },
        modalWasHidden () {
          this.$emit('modal-was-hidden');

          if (this.reloadEditorOnHidden) {
            setTimeout(() => {
              if (this.$store.state.pres) {
                this.$store.dispatch('presReload');
              }
            }, 100);
          }
        },
        updateUsageByPresentation () {
          let uByPres = [];
          for (let usage of this.loadedUsages) {

            if (!usage.presentation || !usage.presentation.id)
              return;

            let item = _.findWhere(uByPres, {presId: usage.presentation.id});

            if (!item) {
              item = {
                presId: usage.presentation.id,
                presentation: usage.presentation,
                canEditPresUsage: usage.can_edit_pres_usage,
                usages: [],
                shareableAllSlides: false,
                pageShareableUsages: [],
                pageBgUsages: [],
                hsTargetUsages: [],
                hsImgUsages: [],
              };
            }

            if (usage.type === 'intro_video') {
              if (usage.can_edit_pres_usage) this.totalReplaceableUsages++;

            } else if (usage.type === 'shared_content') {
              item.shareableAllSlides = true;
              if (usage.can_edit_pres_usage) this.totalReplaceableUsages++;

            } else if (usage.type === 'hotspot_target') {
              if (usage.can_edit_pres_usage) this.totalReplaceableUsages++;
              if (!_.contains(item.hsTargetUsages, usage.page.id)) {
                item.hsTargetUsages.push(usage.page);
              }

            } else if (usage.type === 'hotspot_img') {
              // these are not replaceable
              if (!_.contains(item.hsImgUsages, usage.page.id)) {
                item.hsImgUsages.push(usage.page);
              }

            } else if (usage.type === 'page_background' || usage.type === 'page_animated_background') {
              item.pageBgUsages.push(usage.page);
              if (usage.can_edit_pres_usage) this.totalReplaceableUsages++;

            } else if (usage.type === 'page_shareable') {
              item.pageShareableUsages.push(usage.page);
              if (usage.can_edit_pres_usage) this.totalReplaceableUsages++;
            }

            item.usages.push(usage);

            if (!_.findWhere(uByPres, {presId: item.presId}))
              uByPres.push(item);

          }
          uByPres.forEach((u) => {
            this.usageByPresentation.push(u);
          });
        },
        hideModal () {
          this.$refs.scModal.$vb.modal.hide();
        },
        formatCountWording (num, word, character) {
          return (num > 1) ? word + character : word;
        },
        replaceButtonClicked () {
          let filterType = this.activeItem.content_type;
          if (this.activeItem.content_type === 'image' && this.activeItem.suffix === 'gif') filterType = 'image:gif';
          this.$refs.libraryMainModal.toggleBsModal({
            selectionMode: 'single', dialogType: 'modal',
            filterType: filterType, findUsagesActive: true,
            libStorePrefix: 'libFileReplace', folderId: this.folderId,
          }, (selectedItems) => {
            //console.log('file(s) selected', selectedItems);
            this.replace(selectedItems[0]);
          });

        },
        replace (newUsage) {
          if (this.activeItem.id === newUsage.id) {
            ScNotification.growlErrMsg('Please choose a different file.');
            return;
          }
          if (this.totalReplaceableUsages > 0) {
            let msg = 'This will replace ' +
                this.usagesCountNumText + ' of "' +
                this.activeItem.name + '" with "' +
                newUsage.name + '", are you sure?';
            ScConfirmModal2.modal(msg).then(() => {
              this.replaceUsages(this.activeItem.id, newUsage.id);
            }, () => {});
          }
        },
        getThumbImg (usage, idx) {
          if (usage.usages[idx] && usage.usages[idx].type === 'page_animated_background')
            return usage.usages[idx].page.url_thumb;
          else
            return usage.presentation.url_thumb;
        },
        loadData () {
          this.usagesLoading = true;
          this.loadedUsages.splice(0);
          this.usageByPresentation.splice(0);
          this.totalReplaceableUsages = 0;
          $.ajax({
            method: 'GET',
            url: '/main/library/ajax_find_resource_usage_details',
            data: {resource_id: this.activeItem.id, workspace_id: this.mainMxCurrentWorkshopId}
          }).done((data) => {
            this.loadedUsages = data.usages;
            this.updateUsageByPresentation();
          }).always(() => {
            this.usagesLoading = false;
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading usages');
          });
        },
        replaceUsages (oldId, newId) {
          this.replaceLoading = true;
          this.reloadEditorOnHidden = false;
          ScNotification.growlClear();
          $.ajax({
            method: 'POST',
            url: '/main/library/ajax_replace_resource_usages',
            data: {
              src_resource_id: oldId,
              dest_resource_id: newId, workspace_id: this.mainMxCurrentWorkshopId
            }
          }).done(() => {
            ScNotification.growlSuccessMsg('Replace usages done');
            this.replaceLoading = false;
            this.reloadEditorOnHidden = true;
            this.hideModal();
          }).fail((jqXhr) => {
            this.replaceLoading = false;
            ScNotification.growlXhrError(jqXhr, 'when replacing');
          });
        },
      },
      computed: {
        storeLibState () {
          return this.$store.state[this.libStorePrefix];
        },
        folderId () {
          return this.storeLibState.selectedFolderId;
        },
        usagesCountNumText () {
          if (this.totalReplaceableUsages > 1)
            return this.totalReplaceableUsages + ' usages';
          return this.totalReplaceableUsages + ' usage';
        },
        sortedUsagesByPres () {
          return _.sortBy(this.usageByPresentation, function(item) {
            return item.presentation.title.toLowerCase();
          });
        },
        itemName () {
          return (this.activeItem) ? this.activeItem.name : '';
        }
      }
    }

</script>

<style scoped>
  .thumbnail {
    max-width: 100%;
    height: auto;
    line-height: 1.42857143;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
  }
</style>