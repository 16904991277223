<template>
    <teleport to=".sc-modals-anchor">
    <div ref="scModal" v-vb-is:modal.show @vb-hide-bs-modal="canModalHide"
         @vb-shown-bs-modal="modalShown" @vb-hidden-bs-modal="modalWasHidden"
         class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content shadow-lg">
                <div class="modal-header">
                    <button class="btn-close" data-bs-dismiss="modal"></button>
                    <h4 class="w-100 text-center modal-title">New Folder</h4>
                </div>
                <div class="modal-body">
                    <input type="text" maxlength="251" class="form-control" :disabled="newFolderLoading"
                           ref="folderNameInput" v-model.trim="folderName" @keyup.enter.prevent="validateFolderName"/>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary-orange fw-bold" :disabled="newFolderLoading"
                       @click.prevent="validateFolderName">
                        <ScIcon v-if="newFolderLoading" name="spinnerFW" class="mx-3" />
                        <span v-else>Create</span></button>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>

<script>

    import ScNotification from '../../../shared/common/ScNotification.vue';
    import MainAppMixin from '../../MainAppMixin';
    import ScIcon from '../../../shared/common/ScIcon.vue';

    export default {
        name: 'LibraryCreateFolderModal',
        emits: ['modal-was-hidden'],
        mixins: [MainAppMixin],
        components: {ScIcon},
        props: {
            libStorePrefix: {type: String, default: 'libPage'}
        },
        data () {
            return {
                folderName: 'New Folder',
                newFolderLoading: false
            };
        },
        methods: {
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            canModalHide(e) {
                if (this.newFolderLoading) e.preventDefault();
            },
            modalWasHidden() {
                this.$emit('modal-was-hidden');
            },
            modalShown () {
                this.$refs.folderNameInput.select();
            },
            validateFolderName () {
                if (!this.folderName) {
                    ScNotification.growlErrMsg('Please enter a folder name');
                    return;
                }

                this.newFolderLoading = true;
                this.$store.dispatch(this.libStorePrefix + '/libNewFolder',
                    {workspaceId: this.mainMxCurrentWorkshopId, newFolderName: this.folderName}
                ).then(() => {
                    this.newFolderLoading = false;
                    this.hideModal();
                }, () => {
                    this.newFolderLoading = false;
                });
            },
        },
    }

</script>
