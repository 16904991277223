<template>
    <div style="height: 30px; width: 30px; margin: 7px;
                           background: #99DF9D; border-radius: 100%;fill: #0ec939; display: inline-block;">
        <svg width="30" height="30"><path :d="svgPathD"></path></svg>
    </div>
</template>


<script>

    export default {
        name: 'ScProgressCircle',
        props: {
            value: {type: Number, default: 0}
        },
        computed: {
            svgPathD: function () {
                // SVG fun, adapted from http://jsfiddle.net/lensco/ScURE/
                let pieSize = 30;
                let radius = pieSize / 2;
                let val = this.value;
                if (!val) val = 0;

                // calculate x,y coordinates of the point on the circle to draw the arc to.
                let x = Math.cos((2 * Math.PI) / (100 / val));
                let y = Math.sin((2 * Math.PI) / (100 / val));

                // should the arc go the long way round?
                let longArc = (this.value <= 50) ? 0 : 1;

                // d is a string that describes the path of the slice.
                return "M" + radius + "," + radius + " L" + radius + "," + 0 + ", A" + radius + "," + radius + " 0 " + longArc + ",1 " + (radius + y * radius) + "," + (radius - x * radius) + " z";

            }
        }
    }
</script>