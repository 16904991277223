<template>
    <div v-if="storeLibState">
        <div class="border-bottom bg-white rounded-top" :style="{
                'position': storeLibState.dialogType === 'standalone' ? 'sticky' : 'relative',
                'top': '0' }">
            <div class="container p-0 d-block">
                <div class="library-breadcrumbs pt-2 px-3 ">

                    <button v-if="storeLibState.dialogType === 'modal'" type="button" class="btn-close float-start"
                            data-bs-dismiss="modal" aria-hidden="true"></button>

                    <ol v-if="storeLibState && storeLibState.searchedVal" class="breadcrumb m-0 p-0 justify-content-center">
                        <li class="h2 breadcrumb-item active">
                            Search results for "{{ storeLibState.searchedVal }}"
                            <button type="button" @click.prevent="userSearch(null)"
                               class="btn-close small" style="opacity: 0.3;" v-sc-tooltip="'Clear search results'"></button>
                        </li>
                    </ol>
                    <ol v-else class="breadcrumb mb-0 justify-content-center">
                        <li v-for="crumb in breadcrumbList" :key="crumb.id" :class="['h2 breadcrumb-item', crumb.active ? 'active': '']">
                            <span v-if="!crumb.active">
                                <button type="button" @click.prevent="openFolder(crumb.id)"
                                    class="border-0 bg-white btn-link" style="font-weight: 500;">{{ crumb.name }}</button>
                            </span>
                            <span v-else>
                                {{ crumb.name }}
                                <span v-if="storeLibState.rootFolderId !== crumb.id && crumb.name !== 'Fonts'"
                                      class="ms-3 h5 float-end mb-0 text-muted">
                                    <button @click.prevent="openModal({activeItem: crumb, modalType: 'renameModal'})"
                                        class="btn btn-link ms-3 px-2">
                                        <ScIcon name="pencil" class="text-muted" v-sc-tooltip="'Rename folder'"/></button>
                                    <button @click.prevent="openModal({activeItem: crumb, modalType: 'deleteFolderModal'})"
                                            class="btn btn-link px-2">
                                        <ScIcon name="trashAlt" class="text-muted" v-sc-tooltip="'Delete folder'"/></button>
                                </span>

                            </span>
                        </li>
                    </ol>
                </div>
                <LibraryButtonBar
                        :itemSelect="itemSelect"
                        :selectedItems="selectedItems"
                        :searchedVal="storeLibState.searchedVal"
                        :libStorePrefix = "libStorePrefix"
                        @item-select="selectAllNone"
                        @filter-type-changed="filterTypeChanged"
                        @user-search="userSearch"
                        @open-modal="openModal"
                        @local-upload="uploadFiles(null)"
                        @cloud-upload="cloudUpload">
                </LibraryButtonBar>
            </div>
        </div>
        <div class="container p-0">

            <!-- File Drop Zone -->
            <div style="visibility:hidden; opacity:0" ref="libraryDropZone" class="library-dropzone d-flex justify-content-center">
                <div ref="libraryDropZoneText" class="px-5 text-center align-self-center">{{ libDropZoneTxt }}</div>
            </div>

            <div v-if="storeLibState.showSpinner" class="text-center px-3 pt-5" style="height: calc(100vh - 350px);">
                <ScIcon name="spinnerMedium" class="m-5 text-muted"/>
            </div>

            <div v-else class="library-list px-3 pt-2" :style="{
                    height: storeLibState.dialogType === 'standalone' ? 'auto' : 'calc(100vh - 350px)'
                }">
                <ul v-if="matchedFilesAndFolders.length > 0" class="list-unstyled">
                    <li v-for="doc in matchedFilesAndFolders" :key="doc.id"
                        @click.prevent="selectItem($event, doc, false)"
                        @dblclick.prevent="selectItem($event, doc, true)"
                        :class="[(userPrefs.library_view_style === 'grid' ? 'view-grid m-1 rounded text-center' : 'view-list m-0 px-0 py-1'), (selectedItems.includes(doc) ? 'primary-highlighted' : '')]">

                        <template v-if="isFile(doc)">
                            <p class="img-wrap">
                                <ScProgressCircle v-if="doc.upload_progress_int" :class="doc.id"
                                                  :value="doc.upload_progress_int ? doc.upload_progress_int : 100"
                                                  :style="{margin: userPrefs.library_view_style === 'grid' ? '60px' : '12px'}"></ScProgressCircle>
                                <img v-else @drag="dragInternal" @dragend="dragEndInternal" alt="Thumbnail"
                                     :src="doc.thumb_url" style="width: 150px; height: 150px;"/>
                            </p>
                            <p class="text-wrap">
                                <span class="cut-txt">{{ doc.name }}</span>
                                <template v-if="userPrefs.library_view_style === 'list'">
                                    <small class="text-muted d-inline-block float-end mt-1 file-updated-datetime">{{ mainMxScDateFmt(doc.inserted_date) }}</small>
                                </template>
                            </p>
                        </template>

                        <template v-if="isFolder(doc)">
                            <div @click.prevent="openFolder(doc.id)" style="margin-top: 5px">
                                <span v-if="userPrefs.library_view_style === 'grid'" class="d-inline-flex" style="min-height: 150px">
                                    <ScIcon name="folderLarge" class="folder-icon text-muted my-auto"/>
                                </span>
                                <ScIcon v-if="userPrefs.library_view_style !== 'grid'" name="folderSmallMedium" class="folder-icon text-muted" />
                                <p class="folder-text-wrap">{{ doc.name }}
                                    <template v-if="userPrefs.library_view_style === 'list'">
                                        <small class="text-muted d-inline-block float-end mt-1">{{ mainMxScDateFmt(doc.inserted_date) }}</small>
                                    </template>
                                </p>
                            </div>
                        </template>
                    </li>
                </ul>
                <span v-else class="text-muted text-center d-block my-5">
                    <em v-if="storeLibState.filterType === 'all'">No files to display</em>
                    <em v-else>
                        No {{ formatFilterType }}s to display
                    </em>
                </span>
            </div>

            <div v-if="storeLibState.dialogType === 'modal'" class="d-flex justify-content-between library-cta-bar border-top pt-3 pb-3 px-3">
                <div class="btn disabled text-muted" style="opacity: 1; background: transparent">
                    <ScIcon name="questionCircle" class="me-1"/>
                    <span v-if="storeLibState.selectionMode === 'multiple'" class="me-5">Hold shift or ctrl/cmd to select multiple files</span>
                    <template v-if="uploadingFilesForFolder.length > 0">
                        Uploading {{ formatCountWording(uploadingFilesForFolder.length, 'file') }}...
                    </template>
                    <template v-else >
                        <span>Drag files here to upload</span>
                    </template>
                </div>
                <div>
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal">Close</button>
                    <button type="button" v-if="storeLibState.showChooseButton" @click.prevent="submitChanges"
                       :class="['btn btn-primary-orange fw-bold', (selectedItems.length === 0 || (selectedItems.length > 1 && storeLibState.selectionMode === 'single')) ? 'disabled' : '']" >
                        <ScIcon name="check"/> Choose selected
                    </button>
                </div>
            </div>
        </div>

        <LibraryUploader v-if="storeLibState.selectedFolderId > 0" ref="libContentUploader"
                         :targetFolder="storeLibState.selectedFolderId"
                         :libStorePrefix="libStorePrefix" :options="libraryUploaderOptions"
                         @focus="focusFirstItem" ></LibraryUploader>

        <LibraryCreateFolderModal v-if="createFolderModalShown" @modal-was-hidden="createFolderModalShown=false"
                :libStorePrefix="libStorePrefix"></LibraryCreateFolderModal>

        <LibraryDeleteModal v-if="deleteModalShown" @modal-was-hidden="deleteModalShown=false"
                :libStorePrefix="libStorePrefix" :selectedItems="selectedItems" :activeItem="activeItem"
                @delete-complete="deleteComplete"></LibraryDeleteModal>

        <LibraryMoveModal v-if="moveModalShown" @modal-was-hidden="moveModalShown=false"
                :libStorePrefix="libStorePrefix" :selectedItems="selectedItems"
                @move-complete="clearSelectedItems"></LibraryMoveModal>

        <LibraryUsageModal v-if="usageModalShown" @modal-was-hidden="usageModalWasHidden"
               :libStorePrefix="libStorePrefix" :activeItem="activeItem"></LibraryUsageModal>

    </div>
</template>


<script>

    import _ from "underscore"; // without, where
    import MainAppMixin from "../MainAppMixin";
    import ScNotification from "../../shared/common/ScNotification.vue";
    import ScIcon from "../../shared/common/ScIcon.vue";
    import LibraryButtonBar from "./LibraryButtonBar.vue";
    import ScProgressCircle from "../global/ScProgressCircle.vue";
    import ScRenameModal2 from "../../shared/common/ScRenameModal2.vue";
    import LibraryCreateFolderModal from "./modal/LibraryCreateFolderModal.vue";
    import LibraryDeleteModal from "./modal/LibraryDeleteModal.vue";
    import LibraryMoveModal from "./modal/LibraryMoveModal.vue";
    import LibraryUsageModal from "./modal/LibraryUsageModal.vue";
    import LibraryUploader from "./LibraryUploader.vue";
    import constants from "../../constants";
    import LibraryStore from "../../store/LibraryStore";


    export default {
        name: 'LibraryContent',
        emits: ['submit'],
        mixins: [ MainAppMixin ],
        components: { LibraryUploader, LibraryButtonBar, ScProgressCircle, ScIcon,
            LibraryCreateFolderModal, LibraryDeleteModal, LibraryMoveModal, LibraryUsageModal },
        props: {
            filterType: {type: String, default: 'all'},
            libStorePrefix: {type: String, default: 'libPage'},
            folderId: {type: Number, default: null},
            specialValidateMode: {type: String, default: null},
        },
        data () {
            return {
                // props
                itemSelect: 'none',
                selectedItems: [],
                activeItem: null,
                internalDragging: false,
                activeLibInstance: true,
                libDropZoneTxt: 'Drop to upload',
                libraryUploaderOptions: {
                    maxFileSizeBytes: 1024 * 1024 * 1024, // 1gb
                },
                createFolderModalShown: false,
                deleteModalShown: false,
                moveModalShown: false,
                usageModalShown: false,
            };
        },
        mounted () {
            if (!this.$store.hasModule(this.libStorePrefix)) {
                this.$store.registerModule(this.libStorePrefix, LibraryStore);
            }

            window.addEventListener("dragenter", this.startDragging);
            window.addEventListener("dragleave", this.stopDragging);
            window.addEventListener("dragover", this.startDragging);
            window.addEventListener("drop", this.stopDragging);

            document.addEventListener('sc_event:refresh_resources_thumb', this.notificationFileThumbReady);
            document.addEventListener('sc_event:poster_ready', this.notificationPosterReady);
            document.addEventListener('sc_event:resource_delete', this.notificationFileDeleted);

            this.setOptions();
        },
        beforeUnmount () {
            // reset search value on closing file lib
            this.$store.commit(this.libStorePrefix + '/libResetSearchedVal');

            window.removeEventListener("dragenter", this.startDragging);
            window.removeEventListener("dragleave", this.stopDragging);
            window.removeEventListener("dragover", this.startDragging);
            window.removeEventListener("drop", this.stopDragging);

            document.removeEventListener('sc_event:refresh_resources_thumb', this.notificationFileThumbReady);
            document.removeEventListener('sc_event:poster_ready', this.notificationPosterReady);
            document.removeEventListener('sc_event:resource_delete', this.notificationFileDeleted);
        },


        methods: {
            dragInternal() {
                this.internalDragging = true;
            },
            dragEndInternal() {
                this.internalDragging = false;
            },
            startDragging (e) {
                if (!this.activeLibInstance)
                    return;

                if (this.internalDragging)
                    return;

                this.libDropZoneTxt =
                    (this.storeLibState.searchedVal) ? "Clear your search results to upload files" : 'Drop to upload';

                if (e.type === 'dragover')
                    e.preventDefault();
                let size = this.$el.firstChild.firstChild.getBoundingClientRect();
                this.$refs.libraryDropZone.style.height = (this.storeLibState.dialogType === "modal") ? 'calc(100vh - 350px)' : 'calc(100vh - 248px';
                this.$refs.libraryDropZone.style.width = size.width + 'px';
                this.$refs.libraryDropZone.style.visibility = "";
                this.$refs.libraryDropZone.style.opacity = .75;
                this.$refs.libraryDropZoneText.style.fontSize = "48px";
            },
            stopDragging (e) {
                if (!this.activeLibInstance)
                    return;

                e.preventDefault();
                this.$refs.libraryDropZone.style.visibility = "hidden";
                this.$refs.libraryDropZone.style.opacity = 0;
                this.$refs.libraryDropZoneText.style.fontSize = "48px";

                if (e.type === 'drop' && this.storeLibState.searchedVal) {
                    ScNotification.growlErrMsg('Clear your search results and select a folder to upload files');
                    return;
                }
                if (e.type === 'drop' && this.$refs.libContentUploader)
                    this.$refs.libContentUploader.handleDroppedFiles(Array.from(e.dataTransfer.files));

            },
            sortFilesOrFolders(listOfFilesOrFolders) {
                if (this.storeLibState.searchedVal)
                    return listOfFilesOrFolders.slice();  // server sorted for us when searching
                if (this.userPrefs.library_order_by.includes('id_desc'))
                    return listOfFilesOrFolders.slice().sort((a,b) => b.id - a.id);
                return listOfFilesOrFolders.slice().sort((a,b) => a.name.localeCompare(b.name));
            },
            uploadFiles () {
                this.$refs.libContentUploader.triggerChooseFile();
            },
            cloudUpload () {
                this.$refs.libContentUploader.filePickerUpload()
            },
            openFolder (folderId) {
                this.clearSelectedItems();
                this.$store.dispatch(this.libStorePrefix + '/libFetchFilesAndFolders', {
                    workspaceId: this.userPrefs.currentWorkshopId, folderId: folderId, searchedVal: null});
            },

            submitChanges () {
                for (let item of this.selectedItems) {
                    if (!item) continue;

                    let requestedFilterType = this.storeLibState.requestedFilterType;
                    if (requestedFilterType && requestedFilterType !== 'all') {
                        let msg = null;
                        if (requestedFilterType === 'document' && item.content_type !== 'document')
                            msg = 'Please select a document';
                        else if (requestedFilterType === 'movie' && item.content_type !== 'movie')
                            msg = 'Please select a video file';
                        else if (requestedFilterType === 'cinemagraph' && !(item.content_type === 'movie' || (item.content_type === 'image' && item.suffix === 'gif')))
                            msg = 'Please select a gif or video file';
                        else if (requestedFilterType === 'image:gif' && !(item.content_type === 'image' && item.suffix === 'gif'))
                            msg = 'Please select a gif file';
                        else if (requestedFilterType === 'image' && !(item.content_type === 'image' && item.suffix !== 'gif'))
                            msg = 'Please select an image file';
                        if (msg) {
                            ScNotification.growlErrMsg(msg) ;
                            return;
                        }
                    }
                    if (item.state_const !== constants.RESOURCESTATE_READY) {
                        ScNotification.growlErrMsg(item.name + ' is still uploading, please wait a minute.') ;
                        return;
                    }
                }
                if (this.requestedFilterType === 'image' && this.selectedItems.length > 20) {
                    ScNotification.growlErrMsg('Please select a maximum of 20 images for your slideshow.') ;
                    return;
                }

                if (this.specialValidateMode === 'bg-video-ok' && !this.validateBgVideoOk(this.selectedItems)) {
                    return;
                }
                this.$emit('submit', this.selectedItems);
            },

            validateBgVideoOk (selectedItems){
                if(selectedItems && selectedItems.length === 1 && selectedItems[0]) {
                    let bgVideo = selectedItems[0];
                    if (bgVideo.content_type === 'movie' && bgVideo.poster_date !== null) return true;
                    if (bgVideo.content_type === 'image' && bgVideo.suffix === 'gif') return true;
                }
                ScNotification.growlErrMsg('Please re-upload this video to use it as a Cinemagraph background.');
                return false;
            },

            /* button bar callback functions */
            filterTypeChanged() {
                this.clearSelectedItems();
            },
            /* -- */

            /* modal callback functions */

            renameDoc (newName) {
                //console.log('renameDoc', this.activeItem);
                if (this.isFolder(this.activeItem)) {
                    return this.$store.dispatch(this.libStorePrefix + '/libRenameFolder', { workspaceId: this.userPrefs.currentWorkshopId,
                        folderId: this.activeItem.id, newFolderName: newName});

                } else if (this.isFile(this.activeItem)) {
                    return this.$store.dispatch(this.libStorePrefix + '/libRenameFile', {
                        workspaceId: this.userPrefs.currentWorkshopId, fileId: this.activeItem.id, newFileName: newName
                    }).then(() => {
                        //console.log('renamed ok', this.activeItem);
                        // if editor has a res update it too
                        if (this.$store.state.pres) this.$store.commit('presResourceRenamed', this.activeItem);
                    });
                }
            },
            selectAllNone (val) {
                if (val === 'none') {
                    this.clearSelectedItems();
                    return;
                }
                this.clearSelectedItems();
                this.filteredFiles.forEach((item) => {
                    this.addToSelectedItems(item);
                });
                this.itemSelect = 'all';
            },
            userSearch (val) {
                if (val !== null && val && val.length < 3) return;  // null = clear, must have len > 3
                this.clearSelectedItems();
                this.$store.dispatch(this.libStorePrefix + '/libFetchFilesAndFolders', {
                    workspaceId: this.userPrefs.currentWorkshopId, searchedVal: val,
                    folderId: (val) ? null : ((this.storeLibState.rootFolderId) ? this.storeLibState.rootFolderId : null)
                });
            },
            /* -- */

            formatCountWording (num, word) {
                return (num > 1) ? num + " " + word + "s" : num + " " + word;
            },
            isFolder (doc) {
                //console.log('isFolder', doc.content_type);
                return doc && doc.content_type === 'folder';
            },
            isFile (doc) {
                return doc && doc.content_type !== 'folder';
            },
            clearSelectedItems () {
                this.itemSelect = 'none';
                this.selectedItems.splice(0);
            },
            addToSelectedItems(item) {
                if (!item || item.state_const === null) return;
                this.selectedItems.push(item);
            },
            selectItem (e, doc, submitAfterSelect) {
                if (this.isFolder(doc)) {
                    this.clearSelectedItems();
                    return;
                }

                if (e.shiftKey) {
                    let fileList = this.matchedFilesAndFolders;

                    if (!this.selectedItems.includes(doc)) {
                        this.addToSelectedItems(doc);

                    } else {
                        this.selectedItems = fileList.slice(fileList.indexOf(this.selectedItems[0]), fileList.indexOf(doc));
                        this.addToSelectedItems(doc);
                    }

                    // if shift selected from bottom to top
                    if (fileList.indexOf(this.selectedItems[0]) > fileList.indexOf(this.selectedItems[1]))
                        fileList = fileList.reduceRight((acc, b) => ([...acc, b]), []);

                    let selectedFileList = [];
                    if (fileList.indexOf(this.selectedItems[0]) > fileList.indexOf(doc))
                        selectedFileList = fileList.slice(fileList.indexOf(doc), fileList.indexOf(this.selectedItems[0]));
                    else
                        selectedFileList = fileList.slice(fileList.indexOf(this.selectedItems[0]), fileList.indexOf(doc));

                    for (let el of selectedFileList) {
                        if (!this.selectedItems.includes(el))
                            this.addToSelectedItems(el);
                    }

                    let secondAsLast = this.selectedItems[1];
                    this.selectedItems = _.without(this.selectedItems, secondAsLast);
                    this.addToSelectedItems(secondAsLast);

                    //console.log(this.selectedItems);

                } else if (e.ctrlKey || e.metaKey){
                    if (!this.selectedItems.includes(doc))
                        this.addToSelectedItems(doc);
                    else
                        this.selectedItems = _.without(this.selectedItems, doc);


                } else {
                    if (this.selectedItems.includes(doc) && e.type !== 'dblclick')
                        this.clearSelectedItems();
                    else {
                        this.clearSelectedItems();
                        this.addToSelectedItems(doc);
                    }
                }

                if (submitAfterSelect) this.submitChanges();
            },
            focusFirstItem (guid) {
                setTimeout(() => {
                    //console.log(document.getElementsByClassName(file.guid));
                    let elem = document.getElementsByClassName(guid)[0];
                    if (elem) {
                        elem.scrollIntoView();
                        if (window.innerHeight - elem.getBoundingClientRect().bottom > 140)
                            setTimeout(() => {
                                window.scrollBy(0, -140)
                            }, 300);
                    }
                });
            },

            openModal (data) {
                this.activeItem = data.activeItem;

                if (data.modalType === 'createFolderModal') {
                    this.createFolderModalShown = true;

                } else if (data.modalType === 'renameModal') {
                    let name = (this.isFolder(this.activeItem)) ? this.activeItem.name : this.activeItem.simplename;
                    let maxLength = (this.isFolder(this.activeItem)) ? 64 : 255;
                    ScRenameModal2.modal({
                        name: name, maxLength: maxLength},
                        (newName) => { return this.renameDoc(newName); });

                } else if (data.modalType === 'deleteFileModal' || data.modalType === 'deleteFolderModal') {
                    this.deleteModalShown = true;

                } else if (data.modalType === 'moveModal') {
                    this.moveModalShown = true;

                } else if (data.modalType === 'resourceInfoModal') {
                    this.mainMxShowResInfo(this.activeItem.id);

                } else if (data.modalType === 'usagesModal') {
                    this.activeLibInstance = false;
                    this.usageModalShown = true;
                }
            },
            setOptions () {
                //console.log('LibraryContent setOptions')
                this.$store.commit(this.libStorePrefix + '/libResetWorkspace', {
                    workspaceId: this.mainMxCurrentWorkshopId});

                let fId = (this.folderId) ? this.folderId : (
                    (this.storeLibState.selectedFolderId) ? this.storeLibState.selectedFolderId : null);

                this.$store.dispatch(this.libStorePrefix + '/libFetchFilesAndFolders', {workspaceId: this.userPrefs.currentWorkshopId,
                    folderId: fId,
                    searchedVal: this.storeLibState.searchedVal});

            },
            notificationFileThumbReady(e) {
                this.$store.commit(this.libStorePrefix + '/libNotificationFileThumbReady', e.detail);
            },
            notificationPosterReady(e) {
                this.$store.commit(this.libStorePrefix + '/libNotificationPosterReady', e.detail);
            },
            notificationFileDeleted(e) {
                this.$store.commit(this.libStorePrefix + '/libNotificationFileDeleted', e.detail);
            },
            deleteComplete() {
                if (this.activeItem && this.isFolder(this.activeItem)) {
                    this.openFolder(this.activeItem.parent_id);
                }
                this.clearSelectedItems();
                this.activeItem = null;
            },
            usageModalWasHidden() {
                this.activeLibInstance = true;
                this.usageModalShown = false
            },
        },

        computed: {
            userPrefs() {
                return this.$store.state.user;
            },
            storeLibState() {
                return this.$store.state[this.libStorePrefix];
            },
            filteredFolders () {
                return this.storeLibState.loadedFolders;
            },
            uploadingFilesForFolder () {
                return _.where(this.storeLibState.uploadingFiles, {parent_id: this.storeLibState.selectedFolderId,
                    create_file_in_library: true});
            },
            formatFilterType () {
                if (this.storeLibState.filterType === 'movie') return 'video';
                if (this.storeLibState.filterType === 'image:gif') return 'gif';
                return this.storeLibState.filterType;
            },
            filteredFiles () {
                let allFiles = this.storeLibState.loadedFiles.concat(this.uploadingFilesForFolder);
                let filterType = this.storeLibState.filterType;
                if (filterType === 'all') return allFiles;
                return allFiles.filter((r) => {
                    if (filterType === 'image') {
                        return r.content_type === 'image' && r.suffix !== 'gif';
                    } else if (filterType === 'cinemagraph') {
                        return r.content_type === 'movie' || (r.content_type === 'image' && r.suffix === 'gif');
                    } else if (filterType === 'image:gif') {
                        return r.content_type === 'image' && r.suffix === 'gif';
                    } else  {
                        return r.content_type === filterType;
                    }
                });
            },
            matchedFilesAndFolders () {
                if (this.userPrefs.library_order_by.includes('file_'))
                    return this.sortFilesOrFolders(this.filteredFiles)
                        .concat(this.sortFilesOrFolders(this.filteredFolders));

                return this.sortFilesOrFolders(this.filteredFolders)
                    .concat(this.sortFilesOrFolders(this.filteredFiles));
            },
            breadcrumbList () {
                let list = [];
                if (this.storeLibState.selectedFolderId && this.storeLibState.loadedBreadcrumbs) {
                    for (let crumb of this.storeLibState.loadedBreadcrumbs) {
                        list.unshift({name: crumb.name, id: crumb.id, parent_id: crumb.parent_folder_id, active: false,
                            content_type: 'folder'});
                    }
                }
                list.unshift({name: 'Files', id: this.storeLibState.rootFolderId, parent_folder_id: null,
                        active: false, content_type: 'folder'});
                list[list.length - 1].active = true;
                return list;
            }

        }

    }

</script>


<style scoped>

    .library-list {
        overflow-y: auto;
    }

    .library-list ul li.view-grid {
        display: inline-block;
        width: 170px;
        height: 190px;
        border: 1px solid transparent;
        overflow: hidden;
    }
    .library-list ul li:hover {
        cursor: pointer;
    }
    .library-list ul li.view-list {
        height: 60px;
    }
    .library-list ul li.view-list .fa-folder {
        line-height: 50px;
    }
    .library-list ul li.view-grid p {
        font-size: .9rem;
    }
    .library-list ul li.view-grid .folder-icon {
        height: 100px;
    }
    .library-list ul li.view-list .folder-icon {
        margin-left: .5rem;
    }
    .library-list ul li.view-list p.folder-text-wrap {
        margin-top: 15px;
    }
    .library-list ul li p.img-wrap {
        margin: 5px 0 0 0;
        font-family: unset;
    }
    .library-list ul li.view-list p.img-wrap {
        margin: 0 0 0 5px;
        display: inline-flex;
    }
    .library-list ul li p.img-wrap img {
        max-width: 150px;
        max-height: 150px;
    }
    .library-list ul li.view-list p.img-wrap img {
        max-width: 50px;
        max-height: 50px;
    }
    .library-list ul li p.text-wrap, .library-list ul li p.folder-text-wrap {
        width: 95%;
        display: inline-block;
    }
    .library-list ul li.view-list p.text-wrap, .library-list ul li p.folder-text-wrap {
        margin-top: 13px;
    }
    .library-list ul li.view-grid p.text-wrap, .library-list ul li p.folder-text-wrap {
        margin-top: 5px;
    }
    .library-list ul li p.text-wrap .cut-txt, .library-list ul li p.folder-text-wrap {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .library-list ul li.view-list p.text-wrap .cut-txt {
        width: 80%;
    }
    .library-list ul li.view-grid p.text-wrap .cut-txt {
        width: 93%;
    }

    .library-list ul li p.text-wrap, .library-list ul li p.folder-text-wrap {
        vertical-align: text-bottom;
    }
    .library-list ul li.view-list p.text-wrap, .library-list ul li.view-list p.folder-text-wrap {
        margin-left: 10px;
        width: 90%;
        vertical-align: top;
    }
    .library-list ul li.view-list p.folder-text-wrap, .library-list ul li.view-list p.text-wrap .cut-txt{
        font-size: 1.1rem;
    }
    .library-list ul li.view-list p.folder-text-wrap small, .library-list ul li.view-list p.text-wrap small{
        font-size: .9rem
    }

    .library-list ul li.view-list.selected .file-updated-datetime {
        color: #ffffff !important;
    }

    div.library-dropzone {
        position: absolute;
        z-index: 18000000; /* see README.md webapp z-index values */
        transition: visibility 175ms, opacity 175ms;
        font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
        border: 4px dashed #808080;
        border-radius: 4px;
        background-color: #c0c0c0;
        opacity: .5;
    }

</style>