<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" ref="scModal" v-vb-is:modal.show
         @vb-hidden-bs-modal="modalWasHidden"
         class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-xl modal-dialog-centered">

            <div class="modal-content shadow-lg">
                <div class="modal-body p-0 rounded">
                    <LibraryContent
                            :key="'libContentModal'" :specialValidateMode="specialValidateMode"
                            :libStorePrefix="libStorePrefix"
                                    @submit="submit"></LibraryContent>
                </div>
            </div>

        </div>
    </div>
    </teleport>
</template>


<script>

import LibraryContent from './LibraryContent.vue';

export default {
  name: 'LibraryMainModal',
  components: {LibraryContent},
  data () {
    return {
      isModalShown: false,
      libStorePrefix: null,
      specialValidateMode: null,
      callSuccessOnHiddenWith: null
    }
  },
  methods: {
    modalWasHidden() {
      this.isModalShown = false;
      if (this.callSuccessOnHiddenWith) {
        if (this.successCallback) this.successCallback(this.callSuccessOnHiddenWith);
        this.callSuccessOnHiddenWith = null;
      }
    },
    hideModal() {
      this.$refs.scModal.$vb.modal.hide();
    },
    submit(e) {
      this.callSuccessOnHiddenWith = e;
      this.hideModal();
    },
    toggleBsModal(options, successCallback, closedCallback) {
      this.successCallback = successCallback;
      this.closedCallback = closedCallback;
      this.specialValidateMode = options.specialValidateMode;
      this.libStorePrefix = options.libStorePrefix;
      if (!this.libStorePrefix) {
        this.libStorePrefix = 'libModal'
      }
      this.isModalShown = true;
      setTimeout(() => {  // the store is registered in LibraryContent, give it a tick to load
        this.$store.commit(this.libStorePrefix + '/libSetOptions', {
          showChooseButton: !!successCallback,
          selectionMode: options.selectionMode,
          dialogType: options.dialogType,
          filterType: options.filterType,
          findUsagesActive: options.findUsagesActive,
          folderId: options.folderId
        })
      });
    },

  },

  //
  // to use, call $refs.toggleBsModal
  //


}

</script>

