

import ScNotification from '../shared/common/ScNotification.vue';
import ScCommonUtil from '../shared/common/ScCommonUtil';
import $ from 'jquery';
import _ from 'underscore'; // filter, findWhere, findIndex, contains
import constants from "../constants";

let _stateFn = function () {
    return {
        libWorkspaceId: null,
        selectedFolderId: null,
        rootFolderId: null,
        searchedVal: null,
        loadedFolders: [],
        loadedFiles: [],
        loadedBreadcrumbs: [],
        lastSearchGuid: null,
        showSpinner: false,
        uploadingFiles: [],

        showChooseButton: false,
        selectionMode: 'single',  // single, multiple
        dialogType: 'standalone', // standalone, modal
        filterType: 'all',  // all, image, movie, document, cinemagraph
        requestedFilterType: 'all',  // original filter type when lib opened
        findUsagesActive: false,
    };
};

let _templateFile = {
    id: null,
    name: null,
    simplename: null,
    suffix: null,
    content_type: null,
    state_const: null, // constants.RESOURCESTATE_*
    thumb_url: null,
    url: null,
    video_1024_url: null,
    video_2048_url: null,
    bytesize: 0,
    inserted_date: null,
    poster_date: null,
    thumb_date: null,
    width: 0,
    height: 0,
    parent_id: null,
    // only used during uploading
    upload_key: null,
    upload_progress_int: null,
    workspace_id: null,
    create_file_in_library: true,
    file_obj: null,
    file_uploaded_cb: null,
};

const UPLOAD_PROGRESS_QUEUED = 5;  // waiting to be uploaded
const UPLOAD_PROGRESS_ADDING_BYTES = 10;  // bytes are being uploaded
// between 10 and 90 is the actual % of bytes uploaded
const UPLOAD_PROGRESS_BYTES_ADDED = 90;  // bytes uploaded, next step 'create file'
const UPLOAD_PROGRESS_CREATED_FILE = 95;  // file created waiting for server to process
const UPLOADS_CONCURRENT_ALLOWED = 4;


let _getters = {
    // should always start with "lib" to avoid collisions with other modules

    libGetUploadById: (state) => (fileId) => {
        return state.uploadingFiles.find((uf) => {
            return uf && uf.id === fileId;
        });
    },
};


let _actions = {
    // should always start with "lib" to avoid collisions with other modules

    libFetchFilesAndFolders (context, args) {
        //console.log('libFetchFilesAndFolders', args);
        let guid = ScCommonUtil.guid();
        context.commit('libResetFilesAndFolders', {folderId: args.folderId, guid: guid, searchedVal: args.searchedVal});

        $.ajax({
            type: 'GET', url: '/main/library/ajax_search_files',
            data: {
                workspace_id: args.workspaceId,
                folder_id: args.folderId,
                name_contains: args.searchedVal
            }

        }).done(function (data) {
            //console.log('fetchFiles done', context.state.lastSearchGuid);
            if (guid === context.state.lastSearchGuid) {
                context.commit('libLoadedFolders', data);
                context.commit('libLoadedFiles', data);
            }

        }).fail(function (jqXhr) {
            if (guid === context.state.lastSearchGuid) {
                ScNotification.growlXhrError(jqXhr, 'fetching files and folders');
                context.commit('libLoadedFolders', {files: []});
                context.commit('libLoadedFiles', {files: []});
            }
        });
    },

    libNewFolder (context, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST', url: '/main/library/ajax_folder_create',
                data: {
                    workspace_id: payload.workspaceId,
                    folder_name: payload.newFolderName,
                    destination_id: context.state.selectedFolderId
                }

            }).done((data) => {
                if (data.error) {
                    ScNotification.growlErrMsg("Error creating folder: " + data.errormessage);
                    reject();
                } else {
                    context.commit('libFolderAdded', data);
                    resolve();
                }

            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'creating folder');
                reject();
            });
        });
    },
    libDeleteFolder (context, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST', url: '/main/library/ajax_folder_delete/',
                data: {
                    workspace_id: payload.workspaceId,
                    remove_folder_id: payload.folderId
                }

            }).done(function (data) {
                if (data.error) {
                    ScNotification.growlErrMsg("Error: " + data.errormessage);
                    reject();
                } else {
                    resolve();
                }
            }).fail(function (jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'deleting folder');
                reject();
            });
        });
    },
    libRenameFolder (context, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST', url: '/main/library/ajax_folder_rename',
                data: {
                    workspace_id: payload.workspaceId,
                    id: payload.folderId,
                    name: payload.newFolderName
                }
            }).done(function (data) {
                if (!data.error) {
                    context.commit('libFolderRenamed', payload);
                    resolve();
                } else {
                    ScNotification.growlErrMsg("Error: " + data.errormessage);
                    reject();
                }

            }).fail(function (jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'renaming folder');
                reject();
            });
        });
    },
    libDeleteFile (context, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST', url: '/main/library/ajax_delete',
                data: {
                    workspace_id: payload.workspaceId,
                    resource_id: payload.fileId
                }

            }).done(function (data) {
                //console.log('deleteFile',doc, data);
                if (data.status === 'error') {
                    ScNotification.growlErrMsg('Deletion failed for ' + payload.name + '. ' + data.details);
                    reject();
                } else {
                    context.commit('libFileDeleted', payload.fileId);
                    resolve();
                }

            }).fail(function (jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'deleting file');
                reject();
            });
        });
    },
    libRenameFile (context, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST', url: '/main/library/ajax_file_rename',
                data: {
                    workspace_id: payload.workspaceId,
                    id: payload.fileId,
                    name: payload.newFileName
                }
            }).done(function (data) {
                if (!data.error) {
                    context.commit('libFileRenamed', {fileId: payload.fileId, newName: data.name,
                        newSimpleName: data.simplename});
                    resolve();
                } else {
                    ScNotification.growlErrMsg('Error renaming.');
                    reject();
                }

            }).fail(function (jqXhr) {
                ScNotification.growlXhrError(jqXhr,'renaming file');
                reject();
            });
        });
    },
    libMoveFile (context, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST', url: '/main/library/ajax_move',
                data: {
                    workspace_id: payload.workspaceId,
                    resource_ids: payload.moveItems.join(','),
                    folder_id: payload.moveToFolder,
                }
            }).done(function (data) {
                if (!data.error) {
                    ScNotification.growlSuccessMsg('Successfully moved!');
                    context.commit('libFilesMoved', payload);
                    resolve();
                } else {
                    ScNotification.growlErrMsg('Error moving.');
                    reject();
                }
            }).fail(function (jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'moving file');
                reject();
            });
        });
    },

    libUploadBytes (context, uploadItem) {
        let uploadBytes = (uploadUrl, uploadKey, uploadContentType) => {
            $.ajax({
                type: 'PUT', url: uploadUrl, data: uploadItem.file_obj, cache: false,
                contentType: uploadContentType, processData: false,
                xhr: () => {
                    let uploadXhr = new window.XMLHttpRequest();
                    uploadXhr.upload.addEventListener("progress", (e) => {
                        if (e.lengthComputable) {
                            let completePercentage = 100 * (e.loaded / e.total);
                            if (completePercentage < 1) return;
                            let bytesUploadOutOf = (UPLOAD_PROGRESS_BYTES_ADDED - UPLOAD_PROGRESS_ADDING_BYTES) / 100;
                            let progressInt = Math.round(bytesUploadOutOf * completePercentage)
                                + UPLOAD_PROGRESS_ADDING_BYTES;
                            if (progressInt <= UPLOAD_PROGRESS_ADDING_BYTES) return;
                            if (progressInt >= UPLOAD_PROGRESS_CREATED_FILE) {
                                console.error(progressInt + ' outside range');  // shouldn't happen, check in case
                            } else {
                                context.commit('libFileUploadProgress', {id: uploadItem.id, progressInt: progressInt});
                            }
                        }
                    }, false);
                    return uploadXhr;
                },
            }).done(() => {
                context.commit('libFileUploadDone', {id: uploadItem.id, key: uploadKey});
                if (uploadItem.create_file_in_library) {
                    context.dispatch('libCreateFile', {workspaceId: uploadItem.workspace_id, id: uploadItem.id});
                }
                if (uploadItem.file_uploaded_cb) {
                    uploadItem.file_uploaded_cb(uploadItem);
                    context.dispatch('libUploadBytesProcessQueue');
                }
            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, "uploading bytes of " + uploadItem.name);
                context.commit('libFileUploadError', {id: uploadItem.id});
                context.dispatch('libUploadBytesProcessQueue');
            });
        }
        // get a fresh upload key that we can use to upload directly to
        $.ajax({
            type: 'POST', url: '/main/library/ajax_pre_upload',
            data: {workspace_id: uploadItem.workspace_id, upload_name: uploadItem.file_obj.name},
        }).done((data) => {
            uploadBytes(data.upload_url, data.upload_key, data.upload_content_type); //, data.upload_fields);
        }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "uploading info of " + uploadItem.name);
            context.commit('libFileUploadError', {id: uploadItem.id});
            context.dispatch('libUploadBytesProcessQueue');
        });
    },

    libAddUploadToQueue (context, payload) {
        //console.log('libAddUploadToQueue', payload.guid);
        return new Promise((resolve) => {
            let uploadItem = {
                id: payload.guid,
                name: payload.file.name,
                bytesize: payload.file.size,
                workspace_id: payload.workspaceId,
                create_file_in_library: payload.createFileInLibrary,
                file_obj: payload.file,
                file_uploaded_cb: resolve
            };
            context.commit('libFileUploadStart', uploadItem);
            context.dispatch('libUploadBytesProcessQueue');
        });
    },

    libUploadBytesProcessQueue(context) {
        setTimeout(() => {
            let numInProgress = 0;
            let nextItemToUpload = null;
            context.state.uploadingFiles.forEach((uploadItem) => {
                if (!uploadItem.upload_progress_int) return;
                if (uploadItem.upload_progress_int >= UPLOAD_PROGRESS_ADDING_BYTES
                        && uploadItem.upload_progress_int < UPLOAD_PROGRESS_CREATED_FILE) {
                    numInProgress++;
                }
                if (uploadItem.upload_progress_int === UPLOAD_PROGRESS_QUEUED && nextItemToUpload === null) {
                    nextItemToUpload = uploadItem;
                }
            });
            if (nextItemToUpload !== null) {
                //console.log('libUploadBytesProcessQueue numUploading', numInProgress, nextItemToUpload.id);
                if (numInProgress >= UPLOADS_CONCURRENT_ALLOWED) return;
                context.commit('libFileUploadProgress',
                    {id: nextItemToUpload.id, progressInt: UPLOAD_PROGRESS_ADDING_BYTES});
                context.dispatch('libUploadBytes', nextItemToUpload);
            }
        });
    },

    libCreateFile (context, payload) {
        let item = _.findWhere(context.state.uploadingFiles, {id: payload.id});
        //console.log('libCreateFile', payload, item);
        $.ajax({
            type: 'POST',
            url: '/main/library/ajax_upload_handler',
            data: {
                name: item.name,
                size: item.bytesize,
                key: item.upload_key,
                folder_id: item.parent_id,
                workspace_id: payload.workspaceId
            }
        }).done(function(data) {
            context.commit('libFileCreated', {upload_id: item.id, data: data});

            // reload fonts if we uploaded one
            //console.log('ew file', newFile, vm.$store);
            let lcFileName = data.resource.name ? data.resource.name.toLocaleLowerCase() : '';
            if (lcFileName.lastIndexOf('.ttf') > -1 && context.rootState.fonts) {  // only call if fonts store is loaded
                context.dispatch('fontsFetch', payload.workspaceId);
            }
            context.dispatch('libUploadBytesProcessQueue');

        }).fail(function(jqXhr) {
            if (jqXhr.responseJSON && jqXhr.responseJSON.message) {
                ScNotification.growlErrMsg(jqXhr.responseJSON.message);
            }   else {
                ScNotification.growlXhrError(jqXhr, "creating " + item.name);
            }
            context.commit('libFileUploadError', {id: item.id});
            context.dispatch('libUploadBytesProcessQueue');
        });
    },

};


let _mutations = {
    // should always start with "lib" to avoid collisions with other modules

    libResetFilesAndFolders: function(state, args) {
        //console.log('libResetFilesAndFolders');
        state.showSpinner = true;
        state.loadedFiles.splice(0);
        state.loadedFolders.splice(0);
        state.searchedVal = args.searchedVal;
        state.selectedFolderId = args.folderId;
        state.lastSearchGuid = args.guid;
    },

    libLoadedFolders: function(state, data) {
        state.rootFolderId = data.root_folder_id;
        if (!state.selectedFolderId) state.selectedFolderId = state.rootFolderId;
        state.loadedBreadcrumbs = data.breadcrumbs;
        if (data.folders) {
            data.folders.forEach((f) => {
                let folder = Object.assign({}, _templateFile, f);
                folder.content_type = 'folder';
                state.loadedFolders.push(folder);
            });
        }
    },

    libLoadedFiles: function(state, data) {
        if (data.files) state.loadedFiles.push(...data.files);
        state.showSpinner = false;
    },

    libFolderAdded: function(state, payload) {
        let folder = Object.assign({}, _templateFile, payload);
        folder.content_type = 'folder';
        state.loadedFolders.push(folder);
    },

    libFolderRenamed: function(state, payload) {
        _.findWhere(state.loadedBreadcrumbs, {id: payload.folderId}).name = payload.newFolderName;
    },

    libFileDeleted: function(state, fileId) {
        state.loadedFiles  = _.filter(state.loadedFiles, function (val) {
            return val.id !== fileId
        });
    },

    libFileRenamed: function(state, payload) {
        let res = _.findWhere(state.loadedFiles, {id: payload.fileId});
        res.name = payload.newName;
        res.simplename = payload.newSimpleName;
    },

    libFilesMoved: function(state, payload) {
        for (let fileId of payload.moveItems) {
            state.loadedFiles = _.filter(state.loadedFiles, function (val) {
                return val.id !== fileId
            });
        }
    },


    libNotificationFileDeleted: function(state, payload) {
        state.loadedFiles  = _.filter(state.loadedFiles, function (val) {
            return val.id !== payload.resource_id;
        });
    },

    libFileUploadStart: function(state, payload) {
        //console.log('libFileUploadStart', payload);
        let started = Object.assign({}, _templateFile);
        started.id = payload.id;
        started.bytesize = payload.bytesize;
        started.name = payload.name;
        if (started.name.indexOf('.') > 1) {
            started.simplename = started.name.split('.').slice(0,-1).join('.');
            started.suffix = started.name.split('.').pop();
        }   else {
            started.simplename = started.name;
        }
        started.parent_id = state.selectedFolderId;
        started.upload_progress_int = UPLOAD_PROGRESS_QUEUED;
        started.workspace_id = payload.workspace_id;
        started.file_obj = payload.file_obj;
        started.create_file_in_library = payload.create_file_in_library;
        started.file_uploaded_cb = payload.file_uploaded_cb;

        started.content_type = 'document';
        let extension = started.suffix ? started.suffix.toLocaleLowerCase() : '';
        if (_.contains(['mov', 'mp4', 'm4v', 'avi', 'wmv', 'flv', 'mpg', 'mpeg', 'webm', 'f4v'], extension)) {
            started.content_type = 'movie';
        }   else if (_.contains(['gif', 'jpg', 'jpeg', 'png'], extension)) {
            started.content_type = 'image';
        }   else if (_.contains(['ttf'], extension)) {
            started.content_type = 'font';
        }   else {
            started.content_type = 'document';
        }

        if(!state.uploadingFiles.includes(started)) {
            state.uploadingFiles.push(started);
        }
    },

    libFileUploadProgress: function(state, payload) {
        let item = _.findWhere(state.uploadingFiles, {id: payload.id});
        if (!item) return;
        //console.log('libFileUploadProgress', payload.id, payload.progressInt);
        item.upload_progress_int = payload.progressInt;
    },

    libFileUploadDone: function(state, payload) {
        //console.log('libFileUploadDone', payload);
        let item = _.findWhere(state.uploadingFiles, {id: payload.id});
        if (!item) return;
        item.upload_key = payload.key;
        item.upload_progress_int = UPLOAD_PROGRESS_BYTES_ADDED;
    },

    libFileCreated: function(state, payload) {
        //console.log('libFileCreated', payload);
        let item = _.findWhere(state.uploadingFiles, {id: payload.upload_id});
        if (!item) {
            console.error('uploadingFile not found', payload.upload_id);
            return;
        }

        // remove from uploading list
        let upIdx = _.findIndex(state.uploadingFiles, (file) => { return file.id === payload.upload_id });
        if (upIdx > -1) state.uploadingFiles.splice(upIdx, 1);

        // add to loadedFiles
        let newFile = Object.assign({}, _templateFile, item);
        newFile.id = payload.data.resource.id;
        newFile.name = payload.data.resource.name;
        newFile.upload_progress_int = UPLOAD_PROGRESS_CREATED_FILE;
        newFile.state_const = constants.RESOURCESTATE_PROCESSING;
        newFile.suffix = payload.data.resource.suffix;
        newFile.thumb_url = payload.data.resource.thumb_url;
        newFile.simplename = payload.data.resource.simplename;
        newFile.content_type = payload.data.resource.content_type;
        newFile.inserted_date = payload.data.resource.inserted_date;

        if (state.selectedFolderId === item.parent_id) {  // check we are still in the same folder
            state.loadedFiles.push(newFile);
        }
    },

    libNotificationFileThumbReady: function(state, payload) {
        //console.log('libNotificationFileThumbReady', payload);
        let item = _.findWhere(state.loadedFiles, {id: payload.resource_id});
        if (!item) return;
        if (payload.url) {
            item.url = payload.url;
            item.upload_progress_int = null;
        }
        // need to wait for poster generation if is movie
        if (item.content_type !== 'movie') {
            item.state_const = constants.RESOURCESTATE_READY;
        }
        item.thumb_url = payload.thumb_url;
        item.bytesize = payload.bytesize;
        if (payload.video_1024_url) item.video_1024_url = payload.video_1024_url;
        if (payload.res_width) item.width = payload.res_width;
        if (payload.res_height) item.height = payload.res_height;
    },

    libFileUploadError: function(state, payload) {
        let upIdx = _.findIndex(state.uploadingFiles, (file) => { return file.id === payload.id });
        if (upIdx > -1) state.uploadingFiles.splice(upIdx, 1);
    },

    libNotificationPosterReady: function(state, payload) {
        //console.log('libNotificationPosterReady', payload);
        let item = _.findWhere(state.loadedFiles, {id: payload.resource_id});
        if (!item) return;
        item.poster_date = new Date().toISOString();
        if (payload.video_2048_url) item.video_2048_url = payload.video_2048_url;
        item.state_const = constants.RESOURCESTATE_READY;
    },

    libResetSearchedVal: function(state) {
        state.searchedVal = null;
    },

    libResetWorkspace: function(state, args) {
        //console.log('libResetWorkspace from libraryStore');
        if (state.libWorkspaceId !== args.workspaceId) {
            state.libWorkspaceId = args.workspaceId;
            state.selectedFolderId = null;
            state.rootFolderId = null;
            state.loadedFolders.splice(0);
            state.loadedFiles.splice(0);
        }
    },

    libSetOptions: function(state, options) {
        state.showChooseButton = options.showChooseButton;
        state.selectionMode = options.selectionMode;
        state.dialogType = options.dialogType;
        state.filterType = options.filterType;
        state.requestedFilterType = null;
        if (state.filterType && state.filterType !== 'all') state.requestedFilterType = state.filterType;
        state.findUsagesActive = options.findUsagesActive;
        if (options.folderId) state.selectedFolderId = options.folderId;
    },

    libSetFilterType(state, {filterType}) {
        state.filterType = filterType
    },

};


export default {
    namespaced: true,
    state: _stateFn,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
};
