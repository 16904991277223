

export default {

    CANVAS_ZOOM_LEVELS: [0.25, 0.33, .5, .66, 1, 1.5, 2],
    CANVAS_BLEED_SIZE: 350,
    TOP_CANVAS_BAR_HEIGHT: 34,
    NAVBAR_HEIGHT: 80,
    TOOLBAR_HEIGHT: 60,
    SIDE_PANEL_WIDTH: 250,

    MAX_NUM_LABELS: 100,

    DEFAULT_SCALE_RATIO: 0.5,

    RESOURCETYPE_SHOWCASE_SHARE: 'showcase_share',

    RESOURCESTATE_PROCESSING: 0,
    RESOURCESTATE_READY: 1,

    RES_CONTENT_TYPE_TO_ICON: {
        weburl: 'linkFW',
        document: 'fileFW',
        movie: 'filmFW',
        image: 'imageFW',
        font: 'fileFW'
    },

    RES_CONTENT_TYPE_TO_NAME: {
        weburl: 'URL',
        document: 'Document',
        movie: 'Video'
    },

    ALL_PRIMARY_LAYOUTS: [
        // sort: hd 16:9 land, hs 4:3 land, hd 4:3 port, hd 3:2 land,  ...  SD at end
        {code: 'hd-16by9-landscape', width: 2048, height: 1152, thumb_width: 240, thumb_height: 135,
            title: 'Landscape 16:9', isPopular: true, size_type: 'hd'},
        {code: 'hd-landscape', width: 2048, height: 1536, thumb_width: 240, thumb_height: 180,
            title: 'Landscape 4:3', isPopular: false, size_type: 'hd'},
        {code: 'hd-portrait', width: 1536, height: 2048, thumb_width: 180, thumb_height: 240,
            title: 'Portrait 4:3', isPopular: false, size_type: 'hd'},
        {code: 'hd-3by2-landscape', width: 2048, height: 1365, thumb_width: 240, thumb_height: 160,
            title: 'Landscape 3:2', isPopular: false, size_type: 'hd'},
        {code: 'hd-16by9-portrait', width: 1152, height: 2048, thumb_width: 135, thumb_height: 240,
            title: 'Portrait 16:9', isPopular: false, size_type: 'hd'},
        {code: 'hd-3by2-portrait', width: 1365, height: 2048, thumb_width: 160, thumb_height: 240,
            title: 'Portrait 3:2', isPopular: false, size_type: 'hd'},

        {code: 'sd-16by9-landscape', width: 1280, height: 720, thumb_width: 240, thumb_height: 135,
            title: 'Landscape 16:9 (Low Res)', isPopular: false, size_type: 'sd'},
        {code: 'ipad-landscape', width: 1024, height: 768, thumb_width: 240, thumb_height: 180,
            title: 'Landscape 4:3 (Low Res)', isPopular: false, size_type: 'sd'},
        {code: 'ipad-portrait', width: 768, height: 1024, thumb_width: 180, thumb_height: 240,
            title: 'Portrait 4:3 (Low Res)', isPopular: false, size_type: 'sd'},
        {code: 'surface-pro3-landscape', width: 1080, height: 720, thumb_width: 240, thumb_height: 160,
            title: 'Landscape 3:2 (Low Res)', isPopular: false, size_type: 'sd'},
        {code: 'sd-16by9-portrait', width: 720, height: 1280, thumb_width: 135, thumb_height: 240,
            title: 'Portrait 16:9 (Low Res)', isPopular: false, size_type: 'sd'},
        {code: 'sd-3by2-portrait', width: 720, height: 1080, thumb_width: 160, thumb_height: 240,
            title: 'Portrait 3:2 (Low Res)', isPopular: false, size_type: 'sd'},
    ]

};
