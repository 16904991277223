<template>
    <div class="lib-file-upload-div" style="height:1px;">
        <input class="lib-file-upload" ref="libFileUpload" type="file" multiple="multiple"
               style="visibility:hidden;" @change="uploadChange" :accept="acceptsFileExts" />
    </div>
</template>

<script>

    import $ from 'jquery';
    import _ from 'underscore'; // filter, sortBy, contains
    import ScNotification from '../../shared/common/ScNotification.vue';
    import * as filestack from 'filestack-js';
    import MainAppMixin from '../MainAppMixin';
    import LibraryStore from "../../store/LibraryStore";
    import ScCommonUtil from "../../shared/common/ScCommonUtil";

    const MAX_FILES_FOR_UPLOAD = 200;


    export default {
        name: 'LibraryUploader',
        emits: ['success', 'started', 'focus'],
        mixins: [MainAppMixin],
        props: {
            targetFolder: { type: Number, default: 0 },
            options: { default: null },
            libStorePrefix: {type: String, default: 'libPage'}
        },
        data () {
            return {
              acceptsFileExts: null
            };
        },
        mounted() {
          if (!this.$store.hasModule(this.libStorePrefix)) {  // used directly from NewFromImport so check
            this.$store.registerModule(this.libStorePrefix, LibraryStore);
          }
        },
        methods: {
            handleDroppedFiles (files) {
                //console.log('handleDroppedFiles', files)
                if (!files || files.length === 0 )
                    return;  // nothing dropped

                Object.values(files).forEach((v) => {
                    if (!v.type && !v.name.includes('.')) {
                        ScNotification.growlErrMsg('Unable to upload a folder');
                        files = _.filter(files, function(item) { return !!item.type; });
                    }
                });
                this.prepare(files);

            },
            triggerChooseFile (acceptsFileExts) {
                this.acceptsFileExts = acceptsFileExts ? acceptsFileExts : null;
                setTimeout(() => { // do in next tick so the change in data renders
                  this.$refs.libFileUpload.click();
                }, 250);
            },
            uploadChange (e) {
                if (e.currentTarget.files.length > 0)
                    this.prepare(e.currentTarget.files);

            },

            prepare (files) {
                let validFiles = this.validateFiles(files);
                if (validFiles.length === 0)
                    return;

                let sortedValidFiles = _.sortBy(validFiles, 'name');
                let firstIteration = true;
                for (let file of sortedValidFiles) {
                    this.startUpload(file, firstIteration);
                    firstIteration = false;
                }
                // clear input type='file' value
                this.$refs.libFileUpload.value = '';
            },
            validateFiles (files) {
                if (!files && files.length === 0)
                    return [];
                if (files.length > MAX_FILES_FOR_UPLOAD) {
                    ScNotification.growlErrMsg('Sorry! You can only upload a maximum of '+ MAX_FILES_FOR_UPLOAD
                        + ' files at a time. Please select fewer files and try again.');
                    return [];
                }
                let validArr = [];
                for (let file of files) {
                    let isValid = true;
                    let suffix = file.name ? file.name.split('.').slice(1).pop() : '';

                    if (!file.type && file.size%4096 === 0) {
                        ScNotification.growlErrMsg('"' + file.name + '" cannot be uploaded because it\'s a directory');
                        isValid = false;

                    } else if (file.size > this.options.maxFileSizeBytes) {
                        ScNotification.growlErrMsg('"' + file.name + '" is too big (' +
                            ScCommonUtil.filesizeWithBytes(this.options.maxFileSizeBytes) + ' limit for uploads)');
                        isValid = false;

                    } else if (this.options && this.options.validExtensions && suffix &&
                            !_.contains(this.options.validExtensions, suffix.toLowerCase())) {
                        ScNotification.growlErrMsg('"' + file.name + '" is not of a valid type.');
                        isValid = false;
                    }
                    if (isValid) validArr.push(file);
                }
                return validArr;
            },

            startUpload (file, isFirst) {
                let payload = {
                    guid: ScCommonUtil.guid(),
                    file: file,
                    workspaceId: this.mainMxCurrentWorkshopId,
                    createFileInLibrary: this.targetFolder > 0
                };
                this.$store.dispatch(this.libStorePrefix + '/libAddUploadToQueue', payload).then((e) => {
                    this.$emit('success', e);
                });
                this.$emit('started', payload.guid);
                if (isFirst)
                    this.$emit('focus', payload.guid);
            },

            //
            // FileStack.com picker upload
            //
            filePickerUpload () {
                let fpConf = this.$root.$store.state.user.file_picker_conf
                const client = filestack.init(fpConf.file_picker_key, {
                    security: { policy: fpConf.file_picker_policy, signature: fpConf.file_picker_signature }
                });
                let options = {
                    fromSources: ['local_file_system', 'box', 'dropbox', 'googledrive', 'onedrive'],
                    disableTransformer: true,
                    maxSize: this.options.maxFileSizeBytes,
                    maxFiles: 100,
                    uploadInBackground: false,
                    onFileUploadFinished: (fpfile) => {
                        //console.log('fpfile', fpfile);
                        let item = {id: ScCommonUtil.guid(), name: fpfile.filename, bytesize: fpfile.size};
                        this.$root.$store.commit(this.libStorePrefix + '/libFileUploadStart', item);
                        this.$root.$store.commit(this.libStorePrefix + '/libFileUploadDone',  {
                            id: item.id, key: fpfile.url});
                        this.$root.$store.dispatch(this.libStorePrefix + '/libCreateFile', {
                            workspaceId: this.$root.$store.state.user.currentWorkshopId, id: item.id});
                    },
                    onFileUploadFailed: (fpFile, e) => {
                        console.log('fp fpErr', fpFile, e);
                        ScNotification.growlErrMsg("Error while uploading " + fpFile.filename + ". Please try again.");
                    }
                };
                client.picker(options).open();
                $('.fsp-picker__brand-container').css('display', 'none');
            }

        }

    }

</script>
